
@primary-color: #28AC8D;

#root > .ant-layout {
  min-height: 100vh;
}

.ant-slider:hover > .ant-slider-handle {
  display: block;
}

.hoverable:hover {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.ant-radio-group-vertical {
  display: flex;
  flex-direction: column;
}

.ant-radio-group-vertical > .ant-radio-button-wrapper {
  border-top-width: 0;
  border-left-width: 1px;
}

.ant-radio-group-vertical > .ant-radio-button-wrapper:first-child {
  border-radius: 2px 2px 0 0;
  border-top-width: 1px;
}

.ant-radio-group-vertical > .ant-radio-button-wrapper:last-child {
  border-radius: 0 0 2px 2px;

}

.ant-radio-group-vertical > .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  transition: background-color 0.3s;
  content: '';
}

.ant-radio-group-vertical > .ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 100%;
  height: 1px;
  padding: 0 1px;
  transition: background-color 0.3s;
  content: '';
}

.ant-radio-group-vertical > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-top-color: @primary-color
}

.ant-radio-group-vertical > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):not(:first-child)::before {
  background-color: @primary-color
}

.konvajs-content {
  z-index: 5;
}

.circle-picker > span:nth-child(6n) > div &&&&& {
  margin-right: 0 !important;
}

.ant-layout-header {
  z-index: 10;
}

.glass {
  background: rgba(255, 255, 255, 0.44);
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.narration-controls {
  .ant-popover-content {
    border-color: inherit;

    .ant-popover-arrow {
      border-color: inherit !important;
    }

    .ant-popover-inner {
      border: 1px solid red;
      .ant-popover-inner-content {
        padding: 0
      }
    }
  }

}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;

}

@keyframes blink {
  from, to {
    background-color: #001529;
  }
  50% {
    background-color: red;
  }
}

@-moz-keyframes blink {
  from, to {
    background-color: #001529;
  }
  50% {
    background-color: red;
  }
}

@-webkit-keyframes blink {
  from, to {
    background-color: #001529;
  }
  50% {
    background-color: red;
  }
}

@-o-keyframes blink {
  from, to {
    background-color: #001529;
  }
  50% {
    background-color: red;
  }
}

@primary-color: #28AC8D;